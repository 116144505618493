import lodash from "lodash";
import { createApp } from "vue";
import store from "@/stores";
import ElementPlus from "element-plus";
import locale from 'element-plus/lib/locale/lang/zh-cn'
import i18n from "@/locales";
import router from "@/plugins/router";
import R from "@/plugins/http";
import App from "@/views/App.vue";
import "@/plugins/css/style.css";
import qInput from "@/components/common/input.vue";
import AMapLoader from "@amap/amap-jsapi-loader";
import './plugins/css/base.scss';
import './plugins/css/style.css';
// import '@/utils/report';

window._ = lodash;

const localeElementPlus = require(`@/locales/element/${R.getLocalesName()}`);
let elementInit = {
  size: "default",
  zIndex: 3000,
  locale: localeElementPlus.default,
};

router.afterEach(() => {
  window.scrollTo(0, 0);
});

// 挂载初始化
const app = createApp(App);
app.component("q-input", qInput);
app
  .use(i18n)
  .use(store)
  .use(router)
  .use(ElementPlus, elementInit)
  .use(AMapLoader);
app.config.globalProperties.R = R;
app.mount("#app");