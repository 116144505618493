<template>
    <div class="qw_table">
        <transition name="el-zoom-in-top"><layout-search v-show="searchVis" :options="searchOption"
                :dictData="dialogParams.dictData" @data="searchData" /></transition>
        <div class="table_top">
            <div class="table_btn_left">
                <el-button v-if="btnConfigs.store && btnConfigs.store.show"
                    :disabled="btnConfigs.store && btnConfigs.store.disabled" type="primary" :icon="Plus"
                    @click="openAddDialog">{{ $t('btn.add') }}</el-button>
                <slot name="table_topleft_hook" :dialogParams="dialogParams" :listCount="listCount.data"
                    :multipleSelection="multipleSelection"></slot>
                <el-button v-if="btnConfigs.search && btnConfigs.search.show" @click="searchOpen" type="primary" plain
                    :icon="Search">{{ $t('btn.search2') }}</el-button>
            </div>
            <div class="table_btn_right">
                <el-button v-if="btnConfigs.import && btnConfigs.import.show" :icon="Upload">{{ $t('btn.import')
                }}</el-button>
                <el-button v-if="btnConfigs.export && btnConfigs.export.show" :icon="Download" @click="excelExport">{{
                    $t('btn.export') }}</el-button>
                <el-button v-if="btnConfigs.destroy && btnConfigs.destroy.show" type="danger" @click="deleteData"
                    :icon="Delete">{{ $t('btn.del') }}</el-button>
                <slot name="table_topright_hook"></slot>
            </div>
        </div>
        <slot name="table_top_hook"></slot>
        <el-table ref="multipleTable" :data="listData.listData || []" border style="width: 100%" :lazy="tableCfg.lazy"
            :row-key="columnId" :load="tableCfg.lazy ? lazyLoad : null" @selection-change="handleSelectionChange">
            <el-table-column :minWidth="55" type="selection" width="55" v-if="showSelection" />
            <el-table-column v-for="(v, k) in options" :key="k" :label="v.label"
                :width="v.value == 'created_at' ? (v.width ? v.width || null : '165px') : v.width || null"
                :minWidth="v.minWidth || '165px'" :show-overflow-tooltip="v.overFlow || false" :fixed="v.fixed || null">
                <template #default="scope">
                    <!-- 默认展示文字 -->
                    <span v-if="v.type == 'text' || v.type == undefined">{{ scope.row[v.value] || '-' }}</span>
                    <span v-if="v.type == 'money'"><em v-if="parseFloat(scope.row[v.value]) >= 0"
                            class="moneycss paymoney">+{{ scope.row[v.value] || '-' }}</em><em
                            v-if="parseFloat(scope.row[v.value]) < 0" class="moneycss">{{ scope.row[v.value] ||
                                '-' }}</em></span>
                    <q-tags v-if="v.type == 'tags'" :value="scope.row[v.value]" />
                    <span v-if="v.type == 'tags_array'"><q-tags :styles="{ marginRight: '8px' }"
                            v-for="(arrItem, arrKey) in scope.row[v.value]" :key="arrKey" :value="arrItem" /></span>
                    <span v-if="v.type == 'icon_tags'"><i :class="'icon_tags fa ' + scope.row[v.value]" /><q-tags
                            :value="scope.row[v.value]" /></span>
                    <span v-if="v.type == 'dict'">{{ dictFind(v.value, scope.row[v.value], v.labelName || 'label',
                        v.valueName || 'value') }}</span>
                    <q-tags v-if="v.type == 'dict_tags'" :tag_type="v.tag_type || false"
                        :value="dictFind(v.value, scope.row[v.value], v.labelName || 'label', v.valueName || 'value')" />
                    <el-image v-if="v.type == 'avatar' || v.type == 'image'"
                        :style="v.style || { width: '50px', height: '50px', borderRadius: '4px', textAlign: 'center', lineHeight: '65px', background: R.isEmpty(scope.row[v.value]) ? '#f2f2f2' : 'null', display: 'block' }"
                        :fit="v.fit || 'fill'" :hide-on-click-modal="true" :src="scope.row[v.value]" lazy
                        :preview-src-list="v.perView ? [scope.row[v.value]] : null">
                        <template #error>
                            <el-icon :color="v.errColor || '#888'" :size="v.errorPicSize || 26">
                                <Picture />
                            </el-icon>
                        </template>
                    </el-image>
                    <div class="custom_column" v-if="v.type == 'custom'">
                        <slot :name="v.value" :scopeData="scope.row"></slot>
                    </div>
                </template>
            </el-table-column>
            <el-table-column :minWidth="handleWidth" label="操作" v-if="handleHide" :width="handleWidth" fixed="right">
                <template #default="scope">
                    <slot name="table_column_button">
                        <div class="table_col_handle">
                            <el-button :title="$t('btn.view')" v-if="btnConfigs.show && btnConfigs.show.show" :icon="View"
                                @click="showData(scope.row)" />
                            <el-button :title="$t('btn.edit')" v-if="btnConfigs.update && btnConfigs.update.show"
                                type="primary" @click="openEditDialog(scope.row)" :icon="Edit" />
                            <slot name="table_handleright_hook" :rows="scope.row"></slot>
                            <el-button v-if="btnConfigs.deletes && btnConfigs.deletes.show" type="danger"
                                @click="deleteRowData(scope.row[columnId])" :icon="Delete" />
                        </div>
                    </slot>
                </template>
            </el-table-column>
        </el-table>
        <div class="tabel_pagination" v-if="pagination">
            <!-- <span class="demonstration">Change page size</span> -->
            <el-pagination background layout="total, sizes, prev, pager, next, jumper" :page-size="listParams.per_page"
                :page-sizes="[10, 30, 100, 200, 300, 400]" @size-change="handleSizeChange"
                @current-change="handleCurrentChange" :page-count="listParams.last_page"
                :current-page="listParams.current_page" :total="listParams.total">
            </el-pagination>
        </div>

        <!-- 添加dialog -->
        <el-dialog :destroy-on-close="true" ref="addDialog" custom-class="table_dialog_class" v-model="addVis"
            :title="$t('btn.add')" :width="dialogParams.width">
            <slot name="table_add_hook" :dialogParams="dialogParams">
                <el-form v-if="dialogParams.add && dialogParams.add.column.length > 0" ref="addForm" label-position="right"
                    :rules="dialogParams.rules || null" :model="formData.add" :label-width="dialogParams.labelWidth"
                    :fullscreen="dialogParams.fullscreen">
                    <el-row :gutter="20">
                        <el-col v-for="(v, k) in dialogParams.add.column" :key="k" :span="v.span || dialogParams.span">
                            <div class="table-form-content">
                                <el-form-item :label="v.label" :prop="v.value">
                                    <q-input :params="v" :dictData="dialogParams.dictData || []"
                                        v-model:formData="formData.add[v.value]" />
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>

                    <!-- 按钮处理 -->
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button :loading="loading" type="primary" @click="storeData">{{ $t('btn.determine')
                                }}</el-button>
                                <el-button @click="addVis = false">{{ $t('btn.cancel') }}</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </el-form>
                <el-empty v-else />
            </slot>
        </el-dialog>

        <!-- 编辑dialog -->
        <el-dialog :destroy-on-close="true" ref="editDialog" custom-class="table_dialog_class" v-model="editVis"
            :title="$t('btn.edit')" :width="dialogParams.width">
            <slot name="table_edit_hook" :dialogParams="dialogParams" :formData="formData">
                <el-form v-if="dialogParams.edit && dialogParams.edit.column.length > 0" ref="editForm"
                    label-position="right" :rules="dialogParams.rules || null" :model="formData.edit"
                    :label-width="dialogParams.labelWidth" :fullscreen="dialogParams.fullscreen">
                    <el-row :gutter="20">
                        <el-col v-for="(v, k) in dialogParams.edit.column" :key="k" :span="v.span || dialogParams.span">
                            <div class="table-form-content">
                                <el-form-item :label="v.label" :prop="v.value">
                                    <q-input :params="v" :dictData="dialogParams.dictData || []"
                                        v-model:formData="formData.edit[v.value]" />
                                </el-form-item>
                            </div>
                        </el-col>
                    </el-row>

                    <!-- 按钮处理 -->
                    <el-row :gutter="20">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button :loading="loading" type="primary" @click="updateData">{{ $t('btn.determine')
                                }}</el-button>
                                <el-button @click="editVis = false">{{ $t('btn.cancel') }}</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </el-form>
                <el-empty v-else />
            </slot>
        </el-dialog>

        <!-- 显示dialog -->
        <el-dialog destroy-on-close ref="viewDialog" custom-class="table_dialog_class" v-model="viewVis"
            :title="$t('btn.view')" :width="dialogParams.width">
            <slot name="table_show_hook" :dialogParams="dialogParams" :formData="formData">
                <el-form v-if="dialogParams.view && dialogParams.view.column.length > 0" label-position="right"
                    :label-width="dialogParams.labelWidth" :fullscreen="dialogParams.fullscreen">
                    <el-row :gutter="20">
                        <el-col v-for="(v, k) in dialogParams.view.column" :key="k" :span="v.span || dialogParams.span">
                            <div class="table-form-content">
                                <el-form-item :label="`${v.label ? `${v.label} : ` : ''}`" :prop="v.value">
                                    <q-tags
                                        v-if="v.viewType == 'tag' || (v.viewType == undefined && v.type == undefined) || v.type == 'number' || v.type == 'datetime' || v.type == 'date' || v.type == 'cascader_lazy' || v.type == 'map'"
                                        :value="formData.view[v.value] || ''" :color="v.color || null"
                                        :effect="v.effect || null" :size="v.size || null" :isText="v.isText || false" />
                                    <span v-if="v.viewType == 'text'">{{ formData.view[v.value] }}</span>
                                    <div class="html_view" style='white-space: pre-wrap;' v-if="v.viewType == 'html'"
                                        v-html="editorSplit(formData.view[v.value])"></div>
                                    <span v-if="v.viewType == 'tags_array'"><q-tags :styles="{ marginRight: '8px' }"
                                            v-for="(arrItem, arrKey) in formData.view[v.value]" :key="arrKey"
                                            :value="arrItem" /></span>
                                    <span v-if="v.type == 'dict' || v.viewType == 'dict'">{{ dictFind(v.value,
                                        formData.view[v.value], v.labelName || 'label', v.valueName || 'value') }}</span>
                                    <q-tags v-if="v.type == 'dict_tags' || v.viewType == 'dict_tags'"
                                        :tag_type="v.tag_type || false"
                                        :value="dictFind(v.value, formData.view[v.value], v.labelName || 'label', v.valueName || 'value')" />
                                    <el-image v-if="v.type == 'avatar' || v.type == 'image'"
                                        :style="v.style || { width: '50px', height: '50px', borderRadius: '4px', textAlign: 'center', lineHeight: '65px', background: R.isEmpty(formData.view[v.value]) ? '#f2f2f2' : 'null', display: 'block' }"
                                        :size="100" :fit="v.fit || 'fill'" :hide-on-click-modal="true"
                                        :src="formData.view[v.value]" lazy
                                        :preview-src-list="v.perView ? [formData.view[v.value]] : null">
                                        <template #error>
                                            <el-icon :color="v.errColor || '#888'" :size="v.errorPicSize || 26">
                                                <Picture />
                                            </el-icon>
                                        </template>
                                    </el-image>
                                    <div v-if="v.viewType == 'images'">
                                        <el-image v-for="(imgItem, imgKey) in formData.view[v.value]" class="images_item"
                                            :key="imgKey"
                                            :style="v.style || { width: '50px', marginRight: '10px', height: '50px', borderRadius: '4px', textAlign: 'center', lineHeight: '65px', background: R.isEmpty(formData.view[v.value]) ? '#f2f2f2' : 'null', display: 'inline-block' }"
                                            :size="100" :fit="v.fit || 'fill'" :hide-on-click-modal="true" :src="imgItem"
                                            lazy :preview-src-list="v.perView ? formData.view[v.value] : null">
                                            <template #error>
                                                <el-icon :color="v.errColor || '#888'" :size="v.errorPicSize || 26">
                                                    <Picture />
                                                </el-icon>
                                            </template>
                                        </el-image>
                                    </div>
                                    <div v-if="v.type === 'goodsList'">
                                        <div v-for="(item, index) in formData.view[v.value]" :key="index">
                                            <q-tags :value="`商品${index + 1}：${item.goods_name}`" type="danger"
                                                :isText="v.isText || false" size="large" />

                                            <el-row :gutter="20">
                                                <el-col v-for="(v, k) in goodsListFormConfig" :key="k"
                                                    :span="v.span || dialogParams.span">
                                                    <div class="table-form-content">
                                                        <el-form-item :label="v.label + ' : '" :prop="v.value">
                                                            <q-tags :value="`${item[v.value]}`"
                                                                :isText="v.isText || false" />
                                                        </el-form-item>
                                                    </div>
                                                </el-col>
                                            </el-row>
                                        </div>
                                    </div>
                                </el-form-item>
                            </div>
                        </el-col>
                        <!-- <el-col :span="12"><div class="table-form-content"></div></el-col> -->
                    </el-row>
                </el-form>
                <el-empty v-else />
            </slot>
            <slot name="table_show_bottom_hook" :dialogParams="dialogParams" :formData="formData"></slot>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, ref, watch, provide, onMounted, getCurrentInstance } from "vue"
import { useStore } from 'vuex'
import { useRouter, useRoute } from 'vue-router'
import layoutSearch from "@/components/common/search.vue"
import { Plus, Edit, View, Delete, Download, Upload, Picture, Search } from '@element-plus/icons'
import qTags from "@/components/common/tags.vue"
export default {
    components: { layoutSearch, qTags, Picture },
    props: {
        // label , elabel , prop  显示字段
        showSelection: {
            type: Boolean,
            default: () => true
        },
        searchOption: {
            type: Array,
            default: () => {
                return []
            }
        },
        options: {
            type: Array,
            default: () => {
                return []
            }
        },
        // tabl表数据
        tableData: {
            type: Array,
            default: () => {
                return {}
            }
        },
        // table配置
        tableCfg: {
            type: Object,
            default: () => {
                return {
                    lazy: false,
                    pid: 'id',
                }
            }
        },
        // 操作行宽度
        handleWidth: {
            type: Number,
            default: 140
        }, // 操作栏宽度
        // 操作列是否显示
        handleHide: {
            type: Boolean,
            default: true
        },
        btnConfig: {
            type: Object,
            default: () => {
                return {}
            }
        },
        // 默认获取的行唯一值
        columnId: {
            type: String,
            default: 'id'
        },
        // Pagination 分页显示
        pagination: {
            type: Boolean,
            default: true
        },
        // 列表数据URI
        pageUrl: {
            type: String,
            default: ''
        },

        // 弹框Dialog参数控制
        dialogParam: {
            type: Object,
            default: () => { }
        },

        // 自定义formData
        cutomFormData: {
            type: Object,
            default: () => { }
        },

        // 搜索关键词和翻页数据
        params: {
            type: Object,
            default: () => {
                return {}
            }
        },
        //  自定义导出逻辑
        selfExport: {
            type: Boolean,
            default: false
        }

    },
    emits: ['sizeChange', 'currentChange', 'export', 'reload'],
    setup(props, { emit }) {
        const { ctx, proxy } = getCurrentInstance()
        const route = useRoute()
        const store = useStore()
        const multipleSelection = ref([])
        const searchVis = ref(false)
        const listCount = reactive({ data: {} })
        const listData = reactive({ listData: [] })
        const viewData = reactive({ data: {} })
        const addVis = ref(false) // 添加窗口状态
        const editVis = ref(false) // 添加窗口状态
        const viewVis = ref(false) // 查看窗口状态
        const loading = ref(false) // 按钮状态

        const goodsListFormConfig = [
            { label: '商品数量', value: 'buy_num' },
            { label: '商品单价', value: 'goods_price' },
            { label: '商品批号', value: 'lot_number' },
            { label: '生产企业', value: 'manufacturer' },
            { label: '生产企业许可证号', value: 'production_license_number' },
            { label: '有效期', value: 'expiration_date' },
            { label: '注册证号/备案凭证编号', value: 'registration_license_number' },
            { label: '批准文号', value: 'approval_number' },
        ]

        // 列表参数处理
        let queryParams = {
            per_page: 30,// 每页大小
            total: 0,
            last_page: 1,
            page: 1
        }
        queryParams = Object.assign(queryParams, props.params)
        const listParams = reactive(queryParams) // 分页还要搜索参数

        // 默认按钮状态
        let btnConfig = {
            show: { show: true, url: '', disabled: false }, // 显示
            store: { show: true, url: '', disabled: false }, // 添加
            update: { show: true, url: '', disabled: false }, // 编辑
            destroy: { show: true, url: '', disabled: false }, // 删除
            deletes: { show: false, url: '', disabled: false }, // 删除单行
            search: { show: true, url: '', disabled: false }, // 删除单行
            export: { show: true, url: '', disabled: false },
            import: { show: false, url: '', disabled: false },
        }
        btnConfig = Object.assign(btnConfig, props.btnConfig)
        const btnConfigs = reactive(btnConfig)

        // 弹框默认参数
        let dialogParam = {
            width: '50%',
            labelWidth: '90px', // 字体宽度
            span: 12, // 默认宽度
            column: [], // 默认字段
            fullscreen: false, // 是否全屏
            rules: null,
            destroyOnClose: true,
            isPageDict: false,
            dict: [], // 字典链接 {name:"menus",url:'xxx.com'}
            dictData: {}, // 字典数据 {menus:[]}
            multipleSelection: () => {
                return multipleSelection.value
            },
            addOpenBefore: () => { }, // 打开添加之前执行
            editOpenBefore: () => { }, // 打开编辑之前执行
            editOpenAfter: () => { },
            closeDialog: () => {
                closeDialog()
            }, // 关闭所有弹框
            reloadData: () => {
                Object.assign(listParams, props.params)
                loadData() // 重加载列表数据
            },
            loadView: async (url = null) => { // 获取详情信息
                return await proxy.R.get(url != null ? url : pageUrl + '/' + row[props.columnId])
            },
            add: {
                column: [], // {label:'我的游戏',value:'name'},{label:'定位密钥',value:'name'},{label:'头像',value:'name'}
            },
            edit: {
                column: [],
            },
            view: {
                column: [],
            }
        }
        dialogParam = Object.assign(dialogParam, props.dialogParam)
        const dialogParams = reactive(dialogParam)
        // 字典处理
        const dictHandle = () => {
            if (dialogParam.dict.length <= 0) return
            dialogParam.dict.map(async item => {
                let dictResp = await proxy.R.get(item.url)
                dialogParams.dictData[item.name] = dialogParam.isPageDict ? dictResp['data'] : dictResp
                if (item.addSelect) dialogParams.dictData[item.name].unshift(item.addSelect)
            })
        }
        // 根据键值字典名称获取对应label 最多三层
        const dictFind = (dictName, value, labelName = 'label', valueName = 'value') => {
            let dictVal = '-'
            if (dialogParams.dictData[dictName] && dialogParams.dictData[dictName].length > 0) {
                dialogParams.dictData[dictName].map(item => {
                    if (item[valueName] == value) dictVal = item[labelName]
                    if (item.children && item.children.length > 0) {
                        item.children.map(itemS => {
                            if (itemS[valueName] == value) dictVal = itemS[labelName]
                            if (itemS.children && itemS.children.length > 0) {
                                itemS.children.map(itemT => {
                                    if (itemT[valueName] == value) dictVal = itemT[labelName]
                                })
                            }
                        })
                    }
                })
            }
            return dictVal
        }
        dictHandle()

        const editorSplit = (val) => {
            if (!proxy.R.isEmpty(val)) {
                const splitStr = '##qingwuit##';
                return val.split(splitStr)[0]
            }
        }

        let cutomFormData = { add: {}, edit: {}, view: {} }
        cutomFormData = Object.assign(cutomFormData, props.cutomFormData)
        const formData = reactive(cutomFormData)

        // const editForm = reactive({})

        // 如果没有传请求接口则默认取该路由链接为接口
        // let propRefs = toRefs(props) // 解构props
        let pageUrl = props.pageUrl // 组成链接
        if (proxy.R.isEmpty(props.pageUrl)) pageUrl = route.path



        // 选择数据
        const handleSelectionChange = (e) => {
            let idArr = []
            e.map(item => { idArr.push(item[props.columnId]) })
            multipleSelection.value = idArr
        }
        // 修改页面大小
        const handleSizeChange = (e) => {
            listParams.per_page = e
            loadData();
            emit('sizeChange', e)
        }
        // 修改页面内容
        const handleCurrentChange = (e) => {
            listParams.page = e
            if (listParams.per_page) loadData()
            emit('currentChange', e)
        }

        // 获取列表信息
        const loadData = async () => {
            if (props.tableData.data) {
                // if (props.tableData.data.length == 0) return
                listCount.data = props.tableData.count
                listData.listData = props.tableData.data
                listParams.total = parseInt(props.tableData.total)
                listParams.per_page = parseInt(props.tableData.per_page)
                listParams.last_page = parseInt(props.tableData.last_page)
                listParams.page = parseInt(props.tableData.current_page)
                return
            }
            if (!props.tableCfg.lazy) {
                const allData = await proxy.R.get(pageUrl, listParams)
                if (allData.headers && allData.request && typeof (allData.data) == 'string' && allData.data.indexOf('<!doctype html>') > -1) return
                listCount.data = allData.count
                listData.listData = allData.data
                listParams.total = parseInt(allData.total)
                listParams.per_page = parseInt(allData.per_page)
                listParams.last_page = parseInt(allData.last_page)
                listParams.page = parseInt(allData.current_page)
            } else {
                let childrenData = await getChildrenData(0)
                listData.listData = childrenData
                listParams.total = childrenData.length
            }

            // 重新加载字典
            dictHandle()

        }

        // 值发生改变
        watch(() => props.tableData, (e) => {
            // console.log(e)
            loadData()
        })

        const getChildrenData = async (pid = 0) => {
            let childrenData = await proxy.R.get(pageUrl, { isChildren: true, pid: pid })
            childrenData.forEach(item => {
                if (item.has_children != null) {
                    item.hasChildren = true
                }
            })
            return childrenData
        }

        // 懒加载
        const lazyLoad = async (tree, treeNode, resolve) => {
            let childRaw = await getChildrenData(tree[props.tableCfg.pid || 'id'])
            resolve(childRaw)
        }

        // 打开添加编辑弹框
        const openAddDialog = () => {
            dialogParams.addOpenBefore()
            addVis.value = true
        }
        // 添加数据
        const storeData = () => {
            let keys = []
            for (let key in formData.add) {
                keys.push(key)
            }
            dialogParam.add.column.map(item => {
                let newAddData = {};
                if (_.indexOf(keys, item.value) == -1) {
                    newAddData[item.value] = ''
                    Object.assign(formData.add, newAddData)
                }
            })

            proxy.$refs.addForm.validate((valid) => {
                // 验证失败直接断点
                if (!valid) return false
                loading.value = true
                try {
                    proxy.R.post(pageUrl, formData.add).then(res => {
                        console.log(res);
                        if (res) {
                            if (props.tableData.data) {
                                emit('reload')
                            } else {
                                loadData()
                            }
                            addVis.value = false
                            proxy.$message.success(proxy.$t('msg.success'))
                            return Promise.resolve();
                        } else {
                            return Promise.reject();
                        }
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {
                        loading.value = false
                    })
                } catch (error) {
                    loading.value = false
                }
            })
        }

        // 查看数据
        const showData = async (row) => {
            viewVis.value = true
            if (props.dialogParam.view.useOrigin) {
                formData.view = row;
                console.log(row);
            } else {
                formData.view.id = row[props.columnId]
                const newViewData = await proxy.R.get(pageUrl + '/' + row[props.columnId])
                formData.view = newViewData
            }

        }

        // 打开编辑弹框
        const openEditDialog = async (row) => {
            dialogParams.editOpenBefore()
            formData.edit = {}
            formData.edit = await proxy.R.get(pageUrl + '/' + row[props.columnId])
            editVis.value = true
            dialogParams.edit.column.map((item, key) => {
                // placeholder关联字段
                if (item.relation) dialogParams.edit.column[key]['placeholder'] = formData.edit[item.relation]
            })
            dialogParams.editOpenAfter()
        }
        // 编辑数据
        const updateData = () => {
            proxy.$refs.editForm.validate((valid) => {
                // 验证失败直接断点
                if (!valid) return false
                loading.value = true

                // 找出相对应的数据存入，如不存在的不处理
                let newViewData = {}
                dialogParams.edit.column.map(item => {
                    newViewData[item.value] = formData.edit[item.value] || ''
                })
                formData.edit[props.columnId] = formData.edit[props.columnId]

                try {
                    proxy.R.put(pageUrl + '/' + formData.edit[props.columnId], formData.edit).then(res => {
                        loading.value = false
                        if (res) {
                            if (props.tableData.data) {
                                emit('reload')
                            } else {
                                loadData()
                            }

                            editVis.value = false
                            proxy.$message.success(proxy.$t('msg.success'))
                        }
                    }).catch((err) => {
                        console.log(err)
                    }).finally(() => {
                        loading.value = false
                    })
                } catch (error) {
                    loading.value = false
                }
            })
        }

        // 关闭dialog
        const closeDialog = () => {
            addVis.value = false
            editVis.value = false
            viewVis.value = false
        }
        // 删除数据
        const deleteData = async () => {
            // console.log(ctx.$refs,proxy.$refs)
            if (!multipleSelection.value || multipleSelection.value.length <= 0) return proxy.$message.error(proxy.$t('msg.selectErr'))
            let ids = multipleSelection.value.join(',')
            // let delParams = {}
            // delParams[props.columnId] = ids
            ElementPlus.ElMessageBox.confirm(
                proxy.$t('table.delmsg'),
                proxy.$t('table.deltit'),
                {
                    confirmButtonText: proxy.$t('btn.determine'),
                    cancelButtonText: proxy.$t('btn.cancel'),
                    type: 'warning',
                    // center: true,
                }
            ).then(async () => {
                await proxy.R.deletes(pageUrl + '/' + ids, {})
                if (props.tableData.data) {
                    emit('reload')
                } else {
                    loadData()
                }
                return proxy.$message.success(proxy.$t('msg.success')) // ElementPlus.ElMessage.error
            }).catch(() => { })
        }
        // 删除一条数据
        const deleteRowData = async (id) => {
            ElementPlus.ElMessageBox.confirm(
                proxy.$t('table.delmsg'),
                proxy.$t('table.deltit'),
                {
                    confirmButtonText: proxy.$t('btn.determine'),
                    cancelButtonText: proxy.$t('btn.cancel'),
                    type: 'warning',
                    // center: true,
                }
            ).then(async () => {
                await proxy.R.deletes(pageUrl + '/' + id, {})
                if (props.tableData.data) {
                    emit('reload')
                } else {
                    loadData()
                }
                return proxy.$message.success(proxy.$t('msg.success')) // ElementPlus.ElMessage.error
            }).catch(() => { })
        }

        // 搜索
        const searchData = (e) => {
            Object.assign(listParams, e)
            if (props.tableData.data) {
                emit('reload')
            } else {
                loadData()
            }
        }
        const searchOpen = () => {
            if (props.searchOption && props.searchOption.length <= 0) return proxy.$message.error('wait setting.')
            searchVis.value = !searchVis.value
        }

        // excel导出
        const excelExport = () => {
            // 显示字段
            if (props.options.length < 0) return proxy.$message.error(proxy.$t('msg.selectErr'))
            if (props.selfExport) return emit('export');
            let excelExportData = []
            let headerData = [];

            const dictDataMap = {};
            for (const key in props.dialogParam.dictData) {
                dictDataMap[key] = props.dialogParam.dictData[key].reduce((cur, pre) => {
                    cur[pre.value] = pre.label;
                    return cur;
                }, {})
            }

            listData.listData.map(item => {
                let colItem = []
                headerData = []
                // 判断是否有选择数据 没有则取全部数据
                if (!multipleSelection.value || multipleSelection.value.length <= 0) {
                    props.options.map(itemOption => {
                        if (dictDataMap[itemOption.value]) {
                            colItem.push(dictDataMap[itemOption.value][item[itemOption.value]] || '')
                        } else {
                            colItem.push(item[itemOption.value] || '')
                        }

                        headerData.push(itemOption.label)
                    })
                    excelExportData.push(colItem)
                } else {
                    let ids = multipleSelection.value.join(',').split(',')
                    if (_.indexOf(ids, item[props.columnId] + '') > -1) {
                        props.options.map(itemOption => {
                            colItem.push(item[itemOption.value] || '')
                            headerData.push(itemOption.label)
                        })
                        excelExportData.push(colItem)
                    }
                }

            })
            excelExportData.unshift(headerData)
            const ws = XLSX.utils.aoa_to_sheet(excelExportData);
            const wb = XLSX.utils.book_new();
            // XLSX.utils.sheet_add_aoa(ws, [[1,2], [2,3], [3,4]], {origin: "A2"});
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, (store.state.load.routeMenuName || 'table') + ".xlsx");
        }

        // excel导入
        const excelImport = () => { }

        // 初始化获取数据
        onMounted(() => {
            loadData()
        })

        // 把这个对象返回回去
        emit('tableView', dialogParams)

        return {
            handleSelectionChange,
            handleSizeChange,
            handleCurrentChange,
            showData, viewData,
            storeData, updateData, formData, searchOpen,
            deleteData, deleteRowData,
            multipleSelection,
            searchVis, addVis, editVis, viewVis, openEditDialog, openAddDialog, closeDialog, searchData, loading, loadData,
            listCount, listData, listParams, lazyLoad, dictFind, editorSplit,
            btnConfigs, dialogParams,
            excelExport, excelImport,
            Plus, Edit, View, Delete, Download, Upload, Picture, Search,
            goodsListFormConfig
            // routeMenuName:computed(()=>store.state.load.routeMenuName),
        }
    }

};
</script>
<style lang="scss" scoped>
.qw_table {
    height: 100%;

    .moneycss {
        color: rgb(66, 185, 131);

        &.paymoney {
            color: red;
        }
    }

    .table_top {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;

        /* 横向中间自动空间 */
        .table_btn_left {}

        .table_btn_right {}
    }

    .tabel_pagination {
        margin-top: 20px;
    }

    .icon_tags {
        margin-right: 10px;
    }

    .table_col_handle {
        text-align: center;
    }

    .html_view {
        // background: #f8f8f8;
        border: 1px solid #efefef;
        padding: 5px;
    }
}
</style>
<style  lang="scss">
.table_dialog_class {
    .el-dialog__header {
        border-bottom: 1px solid #efefef;
    }
}
</style>

<style lang="scss" scoped>
:deep(.el-form-item__content) {
    margin-left: 0 !important;
}
</style>