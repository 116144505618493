export const routeUriType = ["/Admin/", "/Seller/"];
// 无需登录接口
export const exclude = ["/Admin/login", "/Seller/login"];

import router from "./router";
import { cloneDeep } from 'lodash';

export function loginBaseData() {
  let loginData = [];
  routeUriType.map((item, key) => {
    let tokenName = "";
    tokenName = routeUriType[key].replaceAll("/", "").toLowerCase();
    loginData.push({
      isLogin: false,
      tokenName: tokenName,
      loginName: routeUriType[key],
    });
  });
  // 最后一个是用户的
  loginData.push({ isLogin: false, tokenName: "" });
  return loginData;
}

export function _import(file) {
  return () => import("@/views/" + file + ".vue");
}

// 打开一个新窗口
export function _open(url, newWin = false) {
  if (newWin) window.open(url);
  if (!newWin) window.location.href = url;
}

// 跳转路由
export function _push(path) {
  router.push(path);
}

// 定义地址链接
export function formatUrl(value) {
  // console.log(value)
  if (value.indexOf("://") != -1) {
    // 判断是http 还是https
    const url = indow.location.href;
    let suf = "http://";
    if (url.indexOf("https://") > -1) {
      suf = "https://";
    }
    value = suf + window.location.host + value;
  }
  return value;
}

// 获取当前的路由地址
export function getToken() {
  const url = window.location.href;
  let index = 2;
  let tokenName = "token";
  routeUriType.map((item, key) => {
    if (url.indexOf(item) > -1) {
      index = key;
      tokenName = item.replaceAll("/", "").toLowerCase() + "_token";
    }
  });
  return "Bearer " + localStorage.getItem(tokenName);
}

export function getUploadPath() {
  const url = window.location.href;
  let index = 2;
  let path = "/";
  routeUriType.map((item, key) => {
    if (url.indexOf(item) > -1) {
      index = key;
      path = item;
    }
  });
  return path;
}
//wangditor 中断字符串
export const editSplitStr = "##qingwuit##";
// wangeditor数据处理
export function editorHandle(data, index = 0) {
  if (!data && index == 0) return "";
  if (!data && index == 1) return {};
  const htmls = data.split(editSplitStr);
  if (htmls.length == 2) {
    if (index == 1) return JSON.parse(htmls[index]);
    return htmls[index];
  }
  return "";
}

// 格式化时间戳
export function formatTime(timeVal, type = true) {
  var d = Math.floor(timeVal / (24 * 3600));
  var h = Math.floor((timeVal - 24 * 3600 * d) / 3600);
  var m = Math.floor((timeVal - 24 * 3600 * d - h * 3600) / 60);
  var s = Math.floor(timeVal - 24 * 3600 * d - h * 3600 - m * 60);
  // console.log(d + '天' + hh + '时' + mm + '分' + ss + '秒'); // 打印出转换后的时间
  //  当时分秒小于10的时候补0
  var hh = h < 10 ? "0" + h : h;
  var mm = m < 10 ? "0" + m : m;
  var ss = s < 10 ? "0" + s : s;
  // this.seckills.format_time =  d + '天' + hh + '时' + mm + '分' + ss + '秒';
  if (!type) return hh + " : " + mm + " : " + ss;
  return d + " 天 " + hh + " 时 " + mm + " 分 " + ss + " 秒";
}


//  级联组件格式化数据
export const cascaderFilter = (
  options,
  key = 'value',
  name = 'name',
  children = 'children',
  value = 'value',
  label = 'label',
  renameChildren = 'children',
) => {
  const _options = cloneDeep(options);
  const new_options = _options.map((v) => {
    const obj = {
      [label]: v[name],
      [value]: v[key],
    };
    if (v[children]) {
      obj[renameChildren ? renameChildren : children] = cascaderFilter(v[children], key, name, children, value, label, renameChildren);
    }
    return obj;
  });
  return new_options;
};


//  pc端-商家入驻-展示数据处理成后端数据
export const showDataToSendData = (formData, dialogParams) =>{
  const { dictData, storeColumn, operateColumn, doctorColumn } = dialogParams;
  const _formData = cloneDeep(formData);

  if (_formData.business_license_expire === '1') {
    _formData.business_license_expire = _formData.business_license_expire_date;
  }
  delete _formData.business_license_expire_date;

  if (_formData.instrument_type?.length > 0) {
    const obj = dictData.instrument_type.reduce((cur,pre)=>{
      cur[pre.name] = pre;
      return cur;
    }, {})
    _formData.instrument_type = _formData.instrument_type.map(v=> obj[v].value);
  }

  if (_formData.scope?.length > 0) {
    const obj = dictData.scope.reduce((cur,pre)=>{
      cur[pre.name] = pre;
      return cur;
    }, {})
    _formData.scope = _formData.scope.map(v=> obj[v].code);
  }

  if (_formData.scope_02?.length > 0) {
    const obj = dictData.scope_02.reduce((cur,pre)=>{
      cur[pre.name] = pre;
      return cur;
    }, {})
    _formData.scope_02 = _formData.scope_02.map(v=> obj[v].code);
  }

  if (_formData.scope_17?.length > 0) {
    const obj = dictData.scope_17.reduce((cur,pre)=>{
      cur[pre.name] = pre;
      return cur;
    }, {})
    console.log(obj);
    console.log(_formData.scope_17);
    _formData.scope_17 = _formData.scope_17.map(v=> obj[v].code);
  }

  if (_formData.second_scope_02?.length > 0) {
    const obj = dictData.second_scope_02.reduce((cur,pre)=>{
      cur[pre.name] = pre;
      return cur;
    }, {})
    _formData.second_scope_02 = _formData.second_scope_02.map(v=> obj[v].code);
  }

  if (_formData.second_scope_17?.length > 0) {
    const obj = dictData.second_scope_17.reduce((cur,pre)=>{
      cur[pre.name] = pre;
      return cur;
    }, {})
    _formData.second_scope_17 = _formData.second_scope_17.map(v=> obj[v].code);
  }

  const result = {};
  storeColumn.reduce((cur,pre)=>{
    cur[pre.value] = '';
    return cur;
  }, result);
  operateColumn.reduce((cur,pre)=>{
    cur[pre.value] = '';
    return cur;
  }, result);
  doctorColumn.reduce((cur,pre)=>{
    cur[pre.value] = '';
    return cur;
  }, result)

  for(const key in result){
    if (key === 'location') {
      result.province_id = _formData.location[0];
      result.city_id = _formData.location[1];
      result.region_id = _formData.location[2];
      result.address = _formData.address;
      delete result.location;
    } else if (Array.isArray(_formData[key])) {
      result[key] = _formData[key].join(',');
    } else if (_formData[key] instanceof Object && Object.keys(_formData[key]).includes('path')) {
      result[key] = _formData[key].path;
    } else {
      result[key] = _formData[key];
    }
  }
  return result;

};

/**
 * @description 商家端-商家中心-店铺资质页面-后端数据处理成展示数据的函数
 * @param { formData } 后端返回的资质详情数据;
 * @returns {formData} 控制前端如何展示的formData数据business_license_expire
 * @author xxh
 * */
export const sendDataToShowData = (formData, dictData) => {
  const _formData = cloneDeep(formData);
  _formData.location = [_formData.province_id, _formData.city_id, _formData.region_id];

  //  需要转成字符串的属性
  const stringTypeList = ['business_license_expire', 'instrument_type'];
  stringTypeList.forEach(key => {
    if (_formData[key]) {
      _formData[key] = `${_formData[key]}`;
    }
  })
  //  需要转成数组的属性
  const arrayTypeList = [
    'drugs_license_expire',           //  药品经营许可证有效期
    'business_license_expire',   //  营业执照固定有效期时间
    'instrument_license_expire',      //  医疗器械经营许可证有效期
    'food_license_expire',            //  食品经营许可证有效期
    'pharmacist_register_expire',     //  执业药师注册证有效期
    'scope_02',                       //  02经营范围
    'scope_17',                       //  17经营范围
    'second_scope_02',                //  二类02经营范围
    'second_scope_17',                //  二类17经营范围
    'instrument_type',                //  器械范围
  ];
  
  arrayTypeList.forEach(key => {
    if (_formData[key]) {
      if (key === 'business_license_expire' && _formData[key] === '-1') {
      } else {
        _formData[key] = _formData[key].split(',');
      }
    } else {
      _formData[key] = [];
    }
  });

  //  营业执照有效期处理
  if (Array.isArray(_formData.business_license_expire)) {
    //  固定有效期
    _formData.business_license_expire_date = cloneDeep(_formData.business_license_expire);
    _formData.business_license_expire = '1';
  } else {
    _formData.business_license_expire = '-1';
  }

  //  器械范围处理
  if (_formData.instrument_type.length > 0) {
    const obj = dictData.instrument_type.reduce((cur,pre)=>{
      cur[`${pre.value}`] = pre.name;
      return cur;
    }, {})
    _formData.instrument_type = _formData.instrument_type.map(v=> obj[`${v}`]);
  }

  //  02器械范围处理
  if (_formData.scope_02.length > 0) {
    const obj = dictData.scope_02.reduce((cur,pre)=>{
      cur[`${pre.code}`] = pre.name;
      return cur;
    }, {})
    _formData.scope_02 = _formData.scope_02.map(v=> obj[`${v}`]);
  }

  //  17器械范围处理
  if (_formData.scope_17.length > 0) {
    const obj = dictData.scope_17.reduce((cur,pre)=>{
      cur[`${pre.code}`] = pre.name;
      return cur;
    }, {})
    _formData.scope_17 = _formData.scope_17.map(v=> obj[`${v}`]);
  }

  //  02二类器械范围处理
  if (_formData.second_scope_02.length > 0) {
    const obj = dictData.second_scope_02.reduce((cur,pre)=>{
      cur[`${pre.code}`] = pre.name;
      return cur;
    }, {})
    _formData.second_scope_02 = _formData.second_scope_02.map(v=> obj[`${v}`]);
  }

  //  17二类器械范围处理
  if (_formData.second_scope_17.length > 0) {
    const obj = dictData.second_scope_17.reduce((cur,pre)=>{
      cur[`${pre.code}`] = pre.name;
      return cur;
    }, {})
    _formData.second_scope_17 = _formData.second_scope_17.map(v=> obj[`${v}`]);
  }
  return _formData;

};

/**
 * @description 管理端-店铺中心-列表-详情查看
 * @param { formData } 后端返回的资质详情数据;
 * @param { dialogData.opts } 选项列表，用于匹配各种详情数据中的code值，在前端展示label值
 * @returns {formData} 控制前端如何展示的formData数据
 * @author xxh
 * */
export const storeDetailSendDataToShowData = (formData, opts) => {
  const { areas=[], shopOptions={} } = opts;
  const { mode=[],scope={}, type=[] } = shopOptions;
  if (type.length===0) return {};

  const typeMap = type.reduce((cur,pre)=>{
    cur[pre.code] = pre;
    return cur;
  }, {});
  const modeMap = mode.reduce((cur,pre)=>{
    cur[pre.code] = pre;
    return cur;
  }, {});

  const scope02Map = scope['02'].reduce((cur,pre)=>{
    cur[pre.code] = {...pre, name: `${pre.code}${pre.name}`};
    return cur;
  }, {})
  const scope17Map = scope['17'].reduce((cur,pre)=>{
    cur[pre.code] = {...pre, name: `${pre.code}${pre.name}`}
    return cur;
  }, {})

  const _formData = cloneDeep(formData);
  try {
    //   // 处理营业执照有效期数据
    // if (_formData.business_license_expire == -1) {
    //   _formData.business_license_expire = '长期有效';
    // }

    // 处理店铺地址数据
    const locationFilter = (list, ids=[], arr) => {
      list.forEach(v=> {
        if (v.id == ids[0]) {
          arr.push(v.name);
          ids.shift();
          if (v.children) {
            locationFilter(v.children, ids, arr );
          }
        }
      })
    }
    const arr = [];
    locationFilter(areas, [_formData.province_id, _formData.city_id, _formData.region_id], arr);
    _formData.location = `${arr.join('/')} ${_formData.address}`;

    //  处理店铺类型名字
    _formData.shop_type_name = typeMap[_formData.shop_type]?.name;
    //  处理经营方式
    _formData.shop_mode_name = modeMap[_formData.shop_mode]?.name;
    //  ---处理证件相关----
    const keysList = [
      {
        label: '营业执照',
        props: 'business_license',
      },{
        label: '药品经营许可证',
        props: 'drugs_license',
      },{
        label: '医疗器械许可证',
        props: 'instrument_license',
      },{
        label: '第二类医疗器械经营备案凭证',
        props: 'second_instrument_license',
      },{
        label: '食品经营许可证',
        props: 'food_license',
      },{
        label: '执业药师注册证',
        props: 'pharmacist_register',
      },{
        label: '药品网络销售备案凭证',
        props: 'drugs_net_sale',
      },{
        label: '医疗器械网络销售备案凭证',
        props: 'instrument_net_sale'
      },{
        label: '执业药师证',
        props: 'pharmacist_license',
      },
    ];
    const objList = []; //  用于存放证书对象
    keysList.forEach(item => {
      const {label, props: v} = item;
      if(formData[v]){
        const obj = {
          label
        };
        //  证书图片地址
        obj.url = formData[v].url;
        //  证书有效期
        if (formData[`${v}_expire`]) {
          if (formData[`${v}_expire`] == -1) {
            obj.expire = '长期有效';
          } else {
            obj.expire = '固定有效期';
            obj.expire_date = formData[`${v}_expire`].replace(',','至');
          }
        }
        //  证书编号
        if (formData[`${v}_no`]) {
          obj.no = formData[`${v}_no`];
        }
        if (v === 'instrument_license') {
          obj.range_02 = formData.scope_02?.split(',').map(v=> scope02Map[v]?.name).join('，');
          obj.range_17 = formData.scope_17?.split(',').map(v=> scope17Map[v]?.name).join('，');
        }
        if (v === 'second_instrument_license') {
          obj.range_02 = formData.second_scope_02?.split(',').map(v=> scope02Map[v].name).join('，');
          obj.range_17 = formData.second_scope_17?.split(',').map(v=> scope17Map[v].name).join('，');
        }
        
        objList.push(obj);
      }
    })
    _formData.licenseList = objList;
  } catch (error) {
    console.log(error);
  }
  return _formData;
};

/**
 * @description 商家端-商品上传-展示数据处理成后端数据的函数
 * @params { formData } 控制前端如何展示的formData数据
 * @returns {formData} 后端需要的商品数据;
 * @author xxh
 * */
export const goodsShowDataToSendData = (formData, option) =>{
  const _formData = cloneDeep(formData);
  _formData.goods_images = formData.goods_images.map(v=> v.url || v);
  _formData.goods_master_image = _formData.goods_images[0];
  for(const key in _formData){
    if (option[key] && Array.isArray(_formData[key])) {
      const optionMap = option[key].reduce((cur,pre)=> {
        cur[pre.label] = pre.value;
        return cur;
      }, {})
      _formData[key] = _formData[key].map(v=> optionMap[v]);
    }
  }
  _formData.id = _formData.id || _formData.goods_id;
  return _formData;
};

/**
 * @description 商家端-审核失败商品编辑详情-后端数据处理成展示数据的函数
 * @params { formData } 后端需要的商品数据;
 * @returns {formData} 控制前端如何展示的formData数据
 * @author xxh
 * */
export const goodsSendDataToShowData = (formData) =>{
  const _formData = cloneDeep(formData);
  if (_formData.goods_images) {
    _formData.goods_images = formData.goods_images.map(v=> ({url: v}));
  }
  return _formData;
};

/**
 * @description 商家端-审核中的商品列表-后端数据处理成前端展示数据
 * @params { formData[] } 后端给的商品列表数据;
 * @returns {formData[]} 前端需要的格式的商品列表数据
 * @author xxh
 * */
export const sellerApprovalSendDataToShowData = (list) =>{
  const _list = list.map(v=> ({...v, ...v.data?.goods_info}))
  return _list;
}

//  商家端-审核中的变更商品详情数据-后端数据处理成前端展示数据-过滤值改变的字段并返回
export const sellerUpdateApprovalSendDataToShowData = (data) =>{
  const { goods_info, goods_online = {} } = data;
  const obj = {};
  const keys = Object.keys(goods_online);
  for(const key in goods_info){
    if (JSON.stringify(goods_info[key]) !== JSON.stringify(goods_online[key]) && keys.includes(key)) {
      obj[key] = goods_info[key];
    }
  }
  if (!obj.goods_type) {
    obj.goods_type_only_read = goods_info.goods_type || goods_online.goods_type;
  }
  return cloneDeep(obj);
}

/**
 * @description 后台管理端-商品信息变更列表-后端数据处理成前端展示数据
 * @params { formData[] } 后端给的商品列表数据;
 * @returns {formData[]} 前端需要的格式的商品列表数据
 * @author xxh
 * */
export const adminApprovalSendDataToShowData = (list) =>{
  const _list = list.map(v=> {
    const id = cloneDeep(v).id;
    return {...v, ...v.data.goods_online, ...v.data.goods_info, id }
  })
  return _list;
}